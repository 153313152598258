
import React, { useState } from 'react';
import api from '../../../../../services/api';
import '../../styleCheckList.css';

import {
  TitlesAndQuestionsFaltaRespiratorio,
  listKeysQuestionFaltaRespiratorio,
  titleFormFaltaRespiratorio,
  SecondIdFaltaRespiratorio,
  listAMCOFaltaRespiratorio,
} from '../../formsModel/formFaltaRespiratorioModelNew';
import {
  TitlesAndQuestionsAcidentesHiperbaricos,
  listKeysQuestionAcidentesHiperbaricos,
  titleFormAcidentesHiperbaricos,
  SecondIdAcidentesHiperbaricos,
  listAMCOAcidentesHiperbaricos,
} from '../../formsModel/formAcidentesHiperbaricosModel';
import {
  TitlesAndQuestionsFalhaSistemaLancamento,
  listKeysQuestionFalhaSistemaLancamento,
  titleFormFalhaSistemaLancamento,
  SecondIdFalhaSistemaLancamento,
  listAMCOFalhaSistemaLancamento,
} from '../../formsModel/formFalhaSistemaLancamento';
import {
  TitlesAndQuestionsAprisionamentoMergulhador,
  listKeysQuestionAprisionamentoMergulhador,
  titleFormAprisionamentoMergulhador,
  SecondIdAprisionamentoMergulhador,
  listAMCOAprisionamentoMergulhador,
} from '../../formsModel/formAprisionamentoMergulhador';
import {
  TitlesAndQuestionsExposicaoInadequadaMergulhador,
  listKeysQuestionExposicaoInadequadaMergulhador,
  titleFormExposicaoInadequadaMergulhador,
  SecondIdExposicaoInadequadaMergulhador,
  listAMCOExposicaoInadequadaMergulhador,
} from '../../formsModel/formExposicaoInadequadaMergulhador';
import {
  TitlesAndQuestionsIncendioExplosaoCamara,
  listKeysQuestionIncendioExplosaoCamara,
  titleFormIncendioExplosaoCamara,
  SecondIdIncendioExplosaoCamara,
  listAMCOIncendioExplosaoCamara,
} from '../../formsModel/formIncendioExplosaoCamara';
import {
  TitlesAndQuestionsFalhaEquipamentoIndividual,
  listKeysQuestionFalhaEquipamentoIndividual,
  titleFormFalhaEquipamentoIndividual,
  SecondIdFalhaEquipamentoIndividual,
  listAMCOFalhaEquipamentoIndividual,
} from '../../formsModel/formFalhaEquipamentoIndividual';
import {
  TitlesAndQuestionsContatoAcidentalMergulhador,
  listKeysQuestionContatoAcidentalMergulhador,
  titleFormContatoAcidentalMergulhador,
  SecondIdContatoAcidentalMergulhador,
  listAMCOContatoAcidentalMergulhador,
} from '../../formsModel/formContatoAcidentalMergulhador';
import {
  TitlesAndQuestionsExposicaoMergulhadoresContaminantes,
  listKeysQuestionExposicaoMergulhadoresContaminantes,
  titleFormExposicaoMergulhadoresContaminantes,
  SecondIdExposicaoMergulhadoresContaminantes,
  listAMCOExposicaoMergulhadoresContaminantes,
} from '../../formsModel/formExposicaoMergulhadoresContaminantes';
import {
  TitlesAndQuestionsExposicaoDescargasEletricas,
  listKeysQuestionExposicaoDescargasEletricas,
  titleFormExposicaoDescargasEletricas,
  SecondIdExposicaoDescargasEletricas,
  listAMCOExposicaoDescargasEletricas,
} from '../../formsModel/formExposicaoDescargasEletricas';
import {
  TitlesAndQuestionsExposicaoMergulhadorVidaMarinha,
  listKeysQuestionExposicaoMergulhadorVidaMarinha,
  titleFormExposicaoMergulhadorVidaMarinha,
  SecondIdExposicaoMergulhadorVidaMarinha,
  listAMCOExposicaoMergulhadorVidaMarinha,
} from '../../formsModel/formExposicaoMergulhadorVidaMarinha';
import {
  TitlesAndQuestionsEmergenciasMedicasHiberbaricas,
  listKeysQuestionEmergenciasMedicasHiberbaricas,
  titleFormEmergenciasMedicasHiberbaricas,
  SecondIdEmergenciasMedicasHiberbaricas,
  listAMCOEmergenciasMedicasHiberbaricas,
} from '../../formsModel/formEmergenciasMedicasHiberbaricas';

import VerificationAprisionamentoMergulhador from '../../Verification/verificationAprisionamentoMergulhador';
import VerificationExposicaoInadequadaMergulhador from '../../Verification/verificationExposicaoInadequadaMergulhador';
import VerificationFaltaSistemaRespiratorio from '../../Verification/verificationFaltaSistemaRespiratorio';
import VerificationIncendioExplosaoCamara from '../../Verification/verificationIncendioExplosaoCamara';
import VerificationFalhaEquipamentoIndividual from '../../Verification/verificationFalhaEquipamentoIndividual';
import VerificationContatoAcidentalMergulhador from '../../Verification/verificationContatoAcidentalMergulhador';
import VerificationExposicaoMergulhadoresContaminantes from '../../Verification/verificationExposicaoMergulhadoresContaminantes';
import VerificationExposicaoDescargasEletricas from '../../Verification/verificationExposicaoDescargasEletricas';
import VerificationExposicaoMergulhadorVidaMarinha from '../../Verification/verificationExposicaoMergulhadorVidaMarinha';
import VerificationEmergenciasMedicasHiberbaricas from '../../Verification/verificationEmergenciasMedicasHiberbaricas';
import VerificationFalhaSistemaLancamento from '../../Verification/verificationFalhaSistemaLancamento';

export default function Diagrama2() {
  let [titleForm, setTitleForm] = useState(titleFormFaltaRespiratorio);
  let [limationSubmit, setLimationSubmit] = useState(false);
  let [TitlesAndQuestions, setTitlesAndQuestions] = useState(
    TitlesAndQuestionsFaltaRespiratorio
  );

  let [listAMCO, setListAMCO] = useState(listAMCOFaltaRespiratorio);
  let [listKeysQuestion, setListKeys] = useState(
    listKeysQuestionFaltaRespiratorio
  );
  let [myArray, setArray] = useState([TitlesAndQuestions]);
  let [AnswerForm, setAnswerForm] = useState([]);
  let [AnswerQuestionForm, setAnswerQuestionForm] = useState([]);
  let [SecondId, setSecondId] = useState(SecondIdFaltaRespiratorio);
  let [ListDateAndForm, setListDateAndForm] = useState([]);
  let [selectedEvent, setSelectedEvent] = useState(
    'Falta de Suprimento Respiratório'
  );
  let [SelectedEmpresa, setSelectedEmpresa] = useState(false);
  const [updatedCheckList, setUpdatedCheckList] = useState([]);
  const [comments, setComments] = useState([]);
  let [selectedUpdate, setSelectedUpdate] = useState(true);
  let [submitButton, setSubmitButton] = useState(true);
  let [updateButton, setUpdateButton] = useState(false);
  let [displayCheckOptions, setdisplayCheckOptions] = useState(true);
  let [displaySelectOptions, setdisplaySelectOptions] = useState(false);
  let [displayFormOptions, setdisplayFormOptions] = useState(true);
  let [idForm, setIdForm] = useState('');
  let [questionsSelected, setquestionsSelected] = useState([]);
  const GetFormModel = event => {
    event.preventDefault();

    if (event.target.value === 'Falta de Suprimento Respiratório') {
      setLimationSubmit(false);
      setTitlesAndQuestions(TitlesAndQuestionsFaltaRespiratorio);
      myArray = [TitlesAndQuestionsFaltaRespiratorio];
      setArray([...myArray]);
      listKeysQuestion = listKeysQuestionFaltaRespiratorio;
      setListKeys([...listKeysQuestion]);
      setSecondId(SecondIdFaltaRespiratorio);
      setListAMCO(listAMCOFaltaRespiratorio);
      setSelectedEvent('Falta de Suprimento Respiratório');
      setTitleForm(titleFormFaltaRespiratorio);
    } else if (event.target.value === 'Acidentes Hiperbaricos') {
      setLimationSubmit(false);
      setTitlesAndQuestions(TitlesAndQuestionsAcidentesHiperbaricos);
      myArray = [TitlesAndQuestionsAcidentesHiperbaricos];
      setArray([...myArray]);
      listKeysQuestion = listKeysQuestionAcidentesHiperbaricos;
      setListKeys([...listKeysQuestion]);
      setSecondId(SecondIdAcidentesHiperbaricos);
      setListAMCO(listAMCOAcidentesHiperbaricos);
      setSelectedEvent('Acidentes Hiperbaricos');
      setTitleForm(titleFormAcidentesHiperbaricos);
    } else if (
      event.target.value === 'Falha no Sistema de Lançamento e Recuperação'
    ) {
      setLimationSubmit(false);
      setTitlesAndQuestions(TitlesAndQuestionsFalhaSistemaLancamento);
      myArray = [TitlesAndQuestionsFalhaSistemaLancamento];
      setArray([...myArray]);
      listKeysQuestion = listKeysQuestionFalhaSistemaLancamento;
      setListKeys([...listKeysQuestion]);
      setSecondId(SecondIdFalhaSistemaLancamento);
      setListAMCO(listAMCOFalhaSistemaLancamento);
      setTitleForm(titleFormFalhaSistemaLancamento);
      setSelectedEvent('Falha no Sistema de Lançamento e Recuperação');
    } else if (
      event.target.value === 'Aprisionamento de Mergulhador no Fundo'
    ) {
      setLimationSubmit(false);
      setTitlesAndQuestions(TitlesAndQuestionsAprisionamentoMergulhador);
      myArray = [TitlesAndQuestionsAprisionamentoMergulhador];
      setArray([...myArray]);
      listKeysQuestion = listKeysQuestionAprisionamentoMergulhador;
      setListKeys([...listKeysQuestion]);
      setSecondId(SecondIdAprisionamentoMergulhador);
      setListAMCO(listAMCOAprisionamentoMergulhador);
      setTitleForm(titleFormAprisionamentoMergulhador);
      setSelectedEvent('Aprisionamento de Mergulhador no Fundo');
    } else if (
      event.target.value ===
      'Exposição Inadequada do Mergulhador na Interface de Sistemas Com Diferencial de Pressão'
    ) {
      setLimationSubmit(false);
      setTitlesAndQuestions(TitlesAndQuestionsExposicaoInadequadaMergulhador);
      myArray = [TitlesAndQuestionsExposicaoInadequadaMergulhador];
      setArray([...myArray]);
      listKeysQuestion = listKeysQuestionExposicaoInadequadaMergulhador;
      setListKeys([...listKeysQuestion]);
      setSecondId(SecondIdExposicaoInadequadaMergulhador);
      setListAMCO(listAMCOExposicaoInadequadaMergulhador);
      setTitleForm(titleFormExposicaoInadequadaMergulhador);
      setSelectedEvent(
        'Exposição Inadequada do Mergulhador na Interface de Sistemas Com Diferencial de Pressão'
      );
    } else if (
      event.target.value ===
      'INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES DO SISTEMA DE MERGULHO'
    ) {
      setLimationSubmit(false);
      setTitlesAndQuestions(TitlesAndQuestionsIncendioExplosaoCamara);
      myArray = [TitlesAndQuestionsIncendioExplosaoCamara];
      setArray([...myArray]);
      listKeysQuestion = listKeysQuestionIncendioExplosaoCamara;
      setListKeys([...listKeysQuestion]);
      setSecondId(SecondIdIncendioExplosaoCamara);
      setListAMCO(listAMCOIncendioExplosaoCamara);
      setTitleForm(titleFormIncendioExplosaoCamara);
      setSelectedEvent(
        'INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES DO SISTEMA DE MERGULHO'
      );
    } else if (
      event.target.value === 'Falha do Equipamento Individual do Mergulhador'
    ) {
      setLimationSubmit(false);
      setTitlesAndQuestions(TitlesAndQuestionsFalhaEquipamentoIndividual);
      myArray = [TitlesAndQuestionsFalhaEquipamentoIndividual];
      setArray([...myArray]);
      listKeysQuestion = listKeysQuestionFalhaEquipamentoIndividual;
      setListKeys([...listKeysQuestion]);
      setSecondId(SecondIdFalhaEquipamentoIndividual);
      setListAMCO(listAMCOFalhaEquipamentoIndividual);
      setTitleForm(titleFormFalhaEquipamentoIndividual);
      setSelectedEvent('Falha do Equipamento Individual do Mergulhador');
    } else if (
      event.target.value ===
      'Contato Acidental do Mergulhador com os Propulsores'
    ) {
      setLimationSubmit(false);
      setTitlesAndQuestions(TitlesAndQuestionsContatoAcidentalMergulhador);
      myArray = [TitlesAndQuestionsContatoAcidentalMergulhador];
      setArray([...myArray]);
      listKeysQuestion = listKeysQuestionContatoAcidentalMergulhador;
      setListKeys([...listKeysQuestion]);
      setSecondId(SecondIdContatoAcidentalMergulhador);
      setListAMCO(listAMCOContatoAcidentalMergulhador);
      setTitleForm(titleFormContatoAcidentalMergulhador);
      setSelectedEvent('Contato Acidental do Mergulhador com os Propulsores');
    } else if (
      event.target.value === 'Exposição dos Mergulhadores aos Contaminantes'
    ) {
      setLimationSubmit(false);
      setTitlesAndQuestions(
        TitlesAndQuestionsExposicaoMergulhadoresContaminantes
      );
      myArray = [TitlesAndQuestionsExposicaoMergulhadoresContaminantes];
      setArray([...myArray]);
      listKeysQuestion = listKeysQuestionExposicaoMergulhadoresContaminantes;
      setListKeys([...listKeysQuestion]);
      setSecondId(SecondIdExposicaoMergulhadoresContaminantes);
      setListAMCO(listAMCOExposicaoMergulhadoresContaminantes);
      setTitleForm(titleFormExposicaoMergulhadoresContaminantes);
      setSelectedEvent('Exposição dos Mergulhadores aos Contaminantes');
    } else if (
      event.target.value ===
      'EXPOSIÇÃO A DESCARGAS ELÉTRICAS, IMPACTOS MECÂNICOS E FLUIDOS PRESSURIZADOS NO CORPO DO MERGULHADOR (SUBMERSOS/EMERSOS)'
    ) {
      setTitlesAndQuestions(TitlesAndQuestionsExposicaoDescargasEletricas);
      myArray = [TitlesAndQuestionsExposicaoDescargasEletricas];
      setArray([...myArray]);
      listKeysQuestion = listKeysQuestionExposicaoDescargasEletricas;
      setListKeys([...listKeysQuestion]);
      setSecondId(SecondIdExposicaoDescargasEletricas);
      setListAMCO(listAMCOExposicaoDescargasEletricas);
      setTitleForm(titleFormExposicaoDescargasEletricas);
      setSelectedEvent(
        'EXPOSIÇÃO A DESCARGAS ELÉTRICAS, IMPACTOS MECÂNICOS E FLUIDOS PRESSURIZADOS NO CORPO DO MERGULHADOR (SUBMERSOS/EMERSOS)'
      );
    } else if (
      event.target.value === 'Exposição do Mergulhador A Vida Marinha Nociva'
    ) {
      setLimationSubmit(false);
      setTitlesAndQuestions(TitlesAndQuestionsExposicaoMergulhadorVidaMarinha);
      myArray = [TitlesAndQuestionsExposicaoMergulhadorVidaMarinha];
      setArray([...myArray]);
      listKeysQuestion = listKeysQuestionExposicaoMergulhadorVidaMarinha;
      setListKeys([...listKeysQuestion]);
      setSecondId(SecondIdExposicaoMergulhadorVidaMarinha);
      setListAMCO(listAMCOExposicaoMergulhadorVidaMarinha);
      setTitleForm(titleFormExposicaoMergulhadorVidaMarinha);
      setSelectedEvent('Exposição do Mergulhador A Vida Marinha Nociva');
    } else if (
      event.target.value ===
      'Emergências não hiberbáricas (alteração clínica do mergulhador)'
    ) {
      setLimationSubmit(false);
      setTitlesAndQuestions(TitlesAndQuestionsEmergenciasMedicasHiberbaricas);
      myArray = [TitlesAndQuestionsEmergenciasMedicasHiberbaricas];
      setArray([...myArray]);
      listKeysQuestion = listKeysQuestionEmergenciasMedicasHiberbaricas;
      setListKeys([...listKeysQuestion]);
      setSecondId(SecondIdEmergenciasMedicasHiberbaricas);
      setListAMCO(listAMCOEmergenciasMedicasHiberbaricas);
      setTitleForm(titleFormEmergenciasMedicasHiberbaricas);
      setSelectedEvent(
        'Emergências não hiberbáricas (alteração clínica do mergulhador)'
      );
    } else {
      setLimationSubmit(false);
      setTitlesAndQuestions(TitlesAndQuestionsFaltaRespiratorio);
      myArray = [TitlesAndQuestionsFaltaRespiratorio];
      setArray([...myArray]);
      listKeysQuestion = listKeysQuestionFaltaRespiratorio;
      setListKeys([...listKeysQuestion]);
      setSecondId(SecondIdFaltaRespiratorio);
      setListAMCO(listAMCOFaltaRespiratorio);
      setTitleForm(titleFormFaltaRespiratorio);
      setSelectedEvent('Falta de Suprimento Respiratório');
    }
  };

  const searchSelectedForm = event => {
    setListDateAndForm([]);
    setSelectedUpdate(false);
    setUpdateButton(true);
    setSubmitButton(false);
    setdisplayFormOptions(false);
    let Empresa = event.target.value;

    api
      .get('formchecklist', { params: { empresa: Empresa } })
      .then(res => {
        let data = res.data;

        let list = [];

        data.forEach(el => {
          if (selectedEvent === el.DataUser.evento) {
            list.push({
              id: el._id,
              Checklist: el.AnswerForm,
              AnswerFormComments: el.AnswerFormComments ?? [],
              AnswerDiagram: el.AnswerDiagram,
              Data: el.DataUser.data,
              name: el.DataUser.name,
              email: el.DataUser.email,
              telefone: el.DataUser.telefone,
              empresa: el.DataUser.empresa,
            });
          }
        });

        setListDateAndForm(list);
        setSelectedEmpresa(true);
      })
      .catch(error => {
        window.alert('Dados não encontrados');
        setSelectedEmpresa(false);
      });
  };

  const DiagramResult = event => {
    try {
      setIdForm(ListDateAndForm[event.target.value].id);

      const uniqueKeys = new Set();
      const chavesArray = []

      for (const am_key in myArray[0]) {
        for (const item_key in myArray[0][am_key]) {
          chavesArray.push(item_key)
        }
      }

      const ListDateAndFormWithoutDuplicate = ListDateAndForm[
        event.target.value
      ].Checklist.filter(obj => {
        const chave = Object.keys(obj)[0];
        if (!uniqueKeys.has(chave) && chavesArray.includes(chave)) {
          uniqueKeys.add(chave);
          return true;
        }
        return false;
      });

      setUpdatedCheckList(
        ListDateAndFormWithoutDuplicate.map(obj => ({ ...obj }))
      );

      const userComments =
        ListDateAndForm[event.target.value].AnswerFormComments;

      if (userComments.length === 0) {
        const commentsModel = ListDateAndFormWithoutDuplicate.map(obj => {
          const novoObjeto = {};
          Object.keys(obj).forEach(chave => {
            novoObjeto[chave] = '';
          });
          return novoObjeto;
        });

        setComments(commentsModel);
      } else {
        setComments(userComments.map(obj => ({ ...obj })));
      }

      setdisplaySelectOptions(true);
      setdisplayCheckOptions(false);
      setAnswerForm(ListDateAndForm[event.target.value].Checklist);
      setSelectedUpdate(true);
    } catch (error) {
      alert('Erro ao consultar os dados do formulário');
    }
  };

  const handleUpdate = () => {
    try {
      updatedCheckList.forEach(el => {
        let groupName = Object.keys(el)[0];
        let AnswerCheck = Object.values(el)[0];
        let groupNameSplit = groupName.split(' ');

        if (titleForm === titleFormAprisionamentoMergulhador) {
          VerificationAprisionamentoMergulhador(
            listKeysQuestion,
            setListKeys,
            groupName,
            AnswerCheck,
            myArray,
            setArray,
            questionsSelected,
            setquestionsSelected,
            AnswerForm,
            setAnswerForm,
            AnswerQuestionForm,
            setAnswerQuestionForm
          );
        } else if (titleForm === titleFormFaltaRespiratorio) {
          VerificationFaltaSistemaRespiratorio(
            listKeysQuestion,
            setListKeys,
            groupName,
            AnswerCheck,
            myArray,
            setArray,
            questionsSelected,
            setquestionsSelected,
            AnswerForm,
            setAnswerForm,
            AnswerQuestionForm,
            setAnswerQuestionForm
          );
        } else if (titleForm === titleFormExposicaoInadequadaMergulhador) {
          VerificationExposicaoInadequadaMergulhador(
            listKeysQuestion,
            setListKeys,
            groupName,
            AnswerCheck,
            myArray,
            setArray,
            questionsSelected,
            setquestionsSelected,
            AnswerForm,
            setAnswerForm
          );
        } else if (titleForm === titleFormIncendioExplosaoCamara) {
          VerificationIncendioExplosaoCamara(
            listKeysQuestion,
            setListKeys,
            groupName,
            AnswerCheck,
            myArray,
            setArray,
            questionsSelected,
            setquestionsSelected,
            AnswerForm,
            setAnswerForm
          );
        } else if (titleForm === titleFormFalhaSistemaLancamento) {
          VerificationFalhaSistemaLancamento(
            listKeysQuestion,
            setListKeys,
            groupName,
            AnswerCheck,
            myArray,
            setArray,
            questionsSelected,
            setquestionsSelected,
            AnswerForm,
            setAnswerForm,
            AnswerQuestionForm,
            setAnswerQuestionForm
          );
        } else if (titleForm === titleFormFalhaEquipamentoIndividual) {
          VerificationFalhaEquipamentoIndividual(
            listKeysQuestion,
            setListKeys,
            groupName,
            AnswerCheck,
            myArray,
            setArray,
            questionsSelected,
            setquestionsSelected,
            AnswerForm,
            setAnswerForm
          );
        } else if (titleForm === titleFormContatoAcidentalMergulhador) {
          VerificationContatoAcidentalMergulhador(
            listKeysQuestion,
            setListKeys,
            groupName,
            AnswerCheck,
            myArray,
            setArray,
            questionsSelected,
            setquestionsSelected,
            AnswerForm,
            setAnswerForm,
            AnswerQuestionForm,
            setAnswerQuestionForm
          );
        } else if (titleForm === titleFormExposicaoMergulhadoresContaminantes) {
          VerificationExposicaoMergulhadoresContaminantes(
            listKeysQuestion,
            setListKeys,
            groupName,
            AnswerCheck,
            myArray,
            setArray,
            questionsSelected,
            setquestionsSelected,
            AnswerForm,
            setAnswerForm,
            AnswerQuestionForm,
            setAnswerQuestionForm
          );
        } else if (titleForm === titleFormExposicaoDescargasEletricas) {
          VerificationExposicaoDescargasEletricas(
            listKeysQuestion,
            setListKeys,
            groupName,
            AnswerCheck,
            myArray,
            setArray,
            questionsSelected,
            setquestionsSelected,
            AnswerForm,
            setAnswerForm,
            AnswerQuestionForm,
            setAnswerQuestionForm
          );
        } else if (titleForm === titleFormExposicaoMergulhadorVidaMarinha) {
          VerificationExposicaoMergulhadorVidaMarinha(
            listKeysQuestion,
            setListKeys,
            groupName,
            AnswerCheck,
            myArray,
            setArray,
            questionsSelected,
            setquestionsSelected,
            AnswerForm,
            setAnswerForm,
            AnswerQuestionForm,
            setAnswerQuestionForm
          );
        } else if (titleForm === titleFormEmergenciasMedicasHiberbaricas) {
          VerificationEmergenciasMedicasHiberbaricas(
            listKeysQuestion,
            setListKeys,
            groupName,
            AnswerCheck,
            myArray,
            setArray,
            questionsSelected,
            setquestionsSelected,
            AnswerForm,
            setAnswerForm,
            AnswerQuestionForm,
            setAnswerQuestionForm
          );
        }

        myArray.forEach(el => {
          for (let FirstKey of Object.keys(el)) {
            for (let SecondKey of Object.keys(el[FirstKey])) {
              let KeySplit = SecondKey.split(' ');
              let questionName = el[FirstKey][SecondKey].Question;

              if (groupNameSplit[0] === KeySplit[0]) {
                if (!questionsSelected.includes(groupName)) {
                  for (let el of listKeysQuestion[0][FirstKey]) {
                    if (Object.keys(el)[0] === groupNameSplit[0]) {
                      let dict = {};
                      dict[groupName] = AnswerCheck;
                      AnswerForm.push(dict);
                      setAnswerForm([...AnswerForm]);
                      AnswerQuestionForm.push({
                        tagQuestion: groupName,
                        question: questionName
                          ? questionName
                          : 'Pergunta não encontrada',
                      });
                      setAnswerQuestionForm([...AnswerQuestionForm]);

                      el[groupNameSplit[0]]['Check'].push(AnswerCheck);
                      questionsSelected.push(groupName);
                      setListKeys([...listKeysQuestion]);
                      setquestionsSelected([...questionsSelected]);
                      break;
                    }
                  }
                } else {
                  for (let el of listKeysQuestion[0][FirstKey]) {
                    if (Object.keys(el)[0] === groupNameSplit[0]) {
                      let dict = {};
                      dict[groupName] = AnswerCheck;
                      AnswerForm[questionsSelected.indexOf(groupName)] = dict;
                      setAnswerForm([...AnswerForm]);
                      el[groupNameSplit[0]]['Check'][
                        el[groupNameSplit[0]]['Check'].length - 1
                      ] = AnswerCheck;
                      setListKeys([...listKeysQuestion]);
                      break;
                    }
                  }
                }

                break;
              }
            }

            for (let el of listKeysQuestion[0][FirstKey]) {
              if (Object.keys(el)[0] === groupNameSplit[0]) {
                if (
                  el[groupNameSplit[0]]['Check'].includes('No') &&
                  el[groupNameSplit[0]]['Check'].includes('Yes')
                ) {
                  el[groupNameSplit[0]]['Answer'] = 'yellow';
                  setListKeys([...listKeysQuestion]);
                } else if (
                  el[groupNameSplit[0]]['Check'].includes('Yes') &&
                  !el[groupNameSplit[0]]['Check'].includes('No')
                ) {
                  el[groupNameSplit[0]]['Answer'] = 'green';
                  setListKeys([...listKeysQuestion]);
                } else if (
                  !el[groupNameSplit[0]]['Check'].includes('Yes') &&
                  el[groupNameSplit[0]]['Check'].includes('No')
                ) {
                  el[groupNameSplit[0]]['Answer'] = 'red';
                  setListKeys([...listKeysQuestion]);
                }
                else {
                  el[groupNameSplit[0]]['Answer'] = 'white';
                  setListKeys([...listKeysQuestion]);
                }
              }
            }
          }
        });

        setArray([...myArray]);
      });

      api
        .put('formchecklist', {
          id: idForm,
          AnswerForm: updatedCheckList,
          AnswerDiagram: listKeysQuestion,
          AnswerQuestionForm: AnswerQuestionForm,
          AnswerFormComments: comments,
        })
        .then(response => {
          alert(response.data.message);
          window.location.reload(false);
        })
        .catch(error => {
          alert(`Erro ao atualizar o formulário:${error}`);
        });
    } catch (error) {
      alert(`Erro ao autualizar o formulário:${error}`);
    }
  };

  const findAnswer = key => {
    const respostaEncontrada = updatedCheckList.find(
      obj => Object.keys(obj)[0] === key
    );
    return respostaEncontrada ? respostaEncontrada[key] : null;
  };

  const handleUpdateSelectedAnswer = (event, key) => {
    const { value } = event.target;
    const index = updatedCheckList.findIndex(
      obj => Object.keys(obj)[0] === key
    );

    if (index !== -1) {
      setUpdatedCheckList(prevRespostasAlteradas => {
        const novasRespostas = [...prevRespostasAlteradas];
        novasRespostas[index][key] = value;
        return novasRespostas;
      });
    }else{
      setUpdatedCheckList(prevRespostasAlteradas => {
        const novasRespostas = [...prevRespostasAlteradas];
        novasRespostas.push({[key]:value})
        return novasRespostas;
      });
    }
  };

  /* const findComment = key => {
    const respostaEncontrada = comments.find(
      obj => Object.keys(obj)[0] === key
    );
    return respostaEncontrada ? respostaEncontrada[key] : '';
  };

  const handleComments = (event, key) => {
    const { value } = event.target;
    const index = comments.findIndex(obj => Object.keys(obj)[0] === key);

    if (index !== -1) {
      setComments(prevRespostasAlteradas => {
        const novasRespostas = [...prevRespostasAlteradas];
        novasRespostas[index][key] = value;
        return novasRespostas;
      });
    } else {
      setComments(prevRespostasAlteradas => {
        const novasRespostas = [...prevRespostasAlteradas];
        novasRespostas.push({ [key]: value });
        return novasRespostas;
      });
    }
  }; */

  const handleSubmit = event => {
    event.preventDefault();

    if (TotalQuestions() === AnswerForm.length) {
      let DataUser = {
        evento: event.target[0].value,
        empresa: event.target[1].value,
        name: event.target[2].value,
        email: event.target[3].value,
        telefone: event.target[4].value,
        data: event.target[5].value,
      };

      api
        .post('formchecklist', {
          DataUser: DataUser,
          AnswerForm: AnswerForm,
          AnswerDiagram: listKeysQuestion,
          AnswerQuestionForm: AnswerQuestionForm,
          AnswerFormComments: comments,
        })
        .then(res => {
          alert('Formulário enviado com sucesso');
          document.getElementById('form-diagram').reset();
          AnswerForm = [];
          setAnswerForm();
          window.location.reload(false);
        })
        .catch(error => {
          alert('Erro ao enviar o Formulário. Tente novamente.');
        });
    } else {
      alert('Responda todas as perguntas pra enviar o formulário');
    }
  };

  const HandleChange = e => {
    let nameSplit = e.target.name.split('Space@');
    let groupName = nameSplit[0];
    let questionName = nameSplit[1];

    let AnswerCheck = e.target.value;

    if (titleForm === titleFormAprisionamentoMergulhador) {
      VerificationAprisionamentoMergulhador(
        listKeysQuestion,
        setListKeys,
        groupName,
        AnswerCheck,
        myArray,
        setArray,
        questionsSelected,
        setquestionsSelected,
        AnswerForm,
        setAnswerForm,
        AnswerQuestionForm,
        setAnswerQuestionForm
      );
    } else if (titleForm === titleFormFaltaRespiratorio) {
      VerificationFaltaSistemaRespiratorio(
        listKeysQuestion,
        setListKeys,
        groupName,
        AnswerCheck,
        myArray,
        setArray,
        questionsSelected,
        setquestionsSelected,
        AnswerForm,
        setAnswerForm,
        AnswerQuestionForm,
        setAnswerQuestionForm
      );
    } else if (titleForm === titleFormExposicaoInadequadaMergulhador) {
      VerificationExposicaoInadequadaMergulhador(
        listKeysQuestion,
        setListKeys,
        groupName,
        AnswerCheck,
        myArray,
        setArray,
        questionsSelected,
        setquestionsSelected,
        AnswerForm,
        setAnswerForm
      );
    } else if (titleForm === titleFormIncendioExplosaoCamara) {
      VerificationIncendioExplosaoCamara(
        listKeysQuestion,
        setListKeys,
        groupName,
        AnswerCheck,
        myArray,
        setArray,
        questionsSelected,
        setquestionsSelected,
        AnswerForm,
        setAnswerForm
      );
    } else if (titleForm === titleFormFalhaSistemaLancamento) {
      VerificationFalhaSistemaLancamento(
        listKeysQuestion,
        setListKeys,
        groupName,
        AnswerCheck,
        myArray,
        setArray,
        questionsSelected,
        setquestionsSelected,
        AnswerForm,
        setAnswerForm,
        AnswerQuestionForm,
        setAnswerQuestionForm
      );
    } else if (titleForm === titleFormFalhaEquipamentoIndividual) {
      VerificationFalhaEquipamentoIndividual(
        listKeysQuestion,
        setListKeys,
        groupName,
        AnswerCheck,
        myArray,
        setArray,
        questionsSelected,
        setquestionsSelected,
        AnswerForm,
        setAnswerForm
      );
    } else if (titleForm === titleFormContatoAcidentalMergulhador) {
      VerificationContatoAcidentalMergulhador(
        listKeysQuestion,
        setListKeys,
        groupName,
        AnswerCheck,
        myArray,
        setArray,
        questionsSelected,
        setquestionsSelected,
        AnswerForm,
        setAnswerForm,
        AnswerQuestionForm,
        setAnswerQuestionForm
      );
    } else if (titleForm === titleFormExposicaoMergulhadoresContaminantes) {
      VerificationExposicaoMergulhadoresContaminantes(
        listKeysQuestion,
        setListKeys,
        groupName,
        AnswerCheck,
        myArray,
        setArray,
        questionsSelected,
        setquestionsSelected,
        AnswerForm,
        setAnswerForm,
        AnswerQuestionForm,
        setAnswerQuestionForm
      );
    } else if (titleForm === titleFormExposicaoDescargasEletricas) {
      VerificationExposicaoDescargasEletricas(
        listKeysQuestion,
        setListKeys,
        groupName,
        AnswerCheck,
        myArray,
        setArray,
        questionsSelected,
        setquestionsSelected,
        AnswerForm,
        setAnswerForm,
        AnswerQuestionForm,
        setAnswerQuestionForm
      );
    } else if (titleForm === titleFormExposicaoMergulhadorVidaMarinha) {
      VerificationExposicaoMergulhadorVidaMarinha(
        listKeysQuestion,
        setListKeys,
        groupName,
        AnswerCheck,
        myArray,
        setArray,
        questionsSelected,
        setquestionsSelected,
        AnswerForm,
        setAnswerForm,
        AnswerQuestionForm,
        setAnswerQuestionForm
      );
    } else if (titleForm === titleFormEmergenciasMedicasHiberbaricas) {
      VerificationEmergenciasMedicasHiberbaricas(
        listKeysQuestion,
        setListKeys,
        groupName,
        AnswerCheck,
        myArray,
        setArray,
        questionsSelected,
        setquestionsSelected,
        AnswerForm,
        setAnswerForm,
        AnswerQuestionForm,
        setAnswerQuestionForm
      );
    }

    let groupNameSplit = groupName.split(' ');

    myArray.forEach(el => {
      for (let FirstKey of Object.keys(el)) {
        for (let SecondKey of Object.keys(el[FirstKey])) {
          let KeySplit = SecondKey.split(' ');
          if (groupNameSplit[0] === KeySplit[0]) {
            if (!questionsSelected.includes(groupName)) {
              for (let el of listKeysQuestion[0][FirstKey]) {
                if (Object.keys(el)[0] === groupNameSplit[0]) {
                  let dict = {};
                  dict[groupName] = AnswerCheck;
                  AnswerForm.push(dict);
                  setAnswerForm([...AnswerForm]);
                  AnswerQuestionForm.push({
                    tagQuestion: groupName,
                    question: questionName,
                  });
                  setAnswerQuestionForm([...AnswerQuestionForm]);

                  el[groupNameSplit[0]]['Check'].push(AnswerCheck);
                  questionsSelected.push(groupName);
                  setListKeys([...listKeysQuestion]);
                  setquestionsSelected([...questionsSelected]);
                  break;
                }
              }
            } else {
              for (let el of listKeysQuestion[0][FirstKey]) {
                if (Object.keys(el)[0] === groupNameSplit[0]) {
                  let dict = {};
                  dict[groupName] = AnswerCheck;
                  AnswerForm[questionsSelected.indexOf(groupName)] = dict;
                  setAnswerForm([...AnswerForm]);
                  el[groupNameSplit[0]]['Check'][
                    el[groupNameSplit[0]]['Check'].length - 1
                  ] = AnswerCheck;
                  setListKeys([...listKeysQuestion]);
                  break;
                }
              }
            }

            break;
          }
        }

        for (let el of listKeysQuestion[0][FirstKey]) {
          if (Object.keys(el)[0] === groupNameSplit[0]) {
            if (
              el[groupNameSplit[0]]['Check'].includes('No') &&
              el[groupNameSplit[0]]['Check'].includes('Yes')
            ) {
              el[groupNameSplit[0]]['Answer'] = 'yellow';
              setListKeys([...listKeysQuestion]);
            } else if (
              el[groupNameSplit[0]]['Check'].includes('Yes') &&
              !el[groupNameSplit[0]]['Check'].includes('No')
            ) {
              el[groupNameSplit[0]]['Answer'] = 'green';
              setListKeys([...listKeysQuestion]);
            } else if (
              !el[groupNameSplit[0]]['Check'].includes('Yes') &&
              el[groupNameSplit[0]]['Check'].includes('No')
            ) {
              el[groupNameSplit[0]]['Answer'] = 'red';
              setListKeys([...listKeysQuestion]);
            } else {
              el[groupNameSplit[0]]['Answer'] = 'white';
              setListKeys([...listKeysQuestion]);
            }
          }
        }
      }
    });

    setArray([...myArray]);
  };

  let DateNow = new Date();
  let Day = DateNow.getDate();
  let Month = DateNow.getMonth() + 1;
  let Year = DateNow.getFullYear();
  let StringDate = `${('0' + Day).slice(-2)}/${('0' + Month).slice(
    -2
  )}/${Year}`;

  const TotalQuestions = () => {
    let Array = [];
    Object.keys(TitlesAndQuestions).forEach(FirstKey => {
      Object.keys(TitlesAndQuestions[FirstKey]).forEach(Secondkey => {
        Array.push(Secondkey);
      });
    });

    return Array.length;
  };

  return (
    <div>
      <h2>Selecione o Formulário que você deseja consultar:</h2>
      {!limationSubmit ? (
        <div class="d-flex">
          <select
            id="Select-Embarcacao"
            class="form-select"
            aria-label="Default select example"
            onChange={GetFormModel}
          >
            <option selected>Selecione o Evento</option>
            <option value="Acidentes Hiperbaricos">
             TOPO A - ACIDENTES HIPERBARICOS
            </option>
            <option value="Falta de Suprimento Respiratório">
             TOPO B - FALTA DE SUPRIMENTO RESPIRATÓRIO
            </option>
            <option value="Falha no Sistema de Lançamento e Recuperação">
              TOPO C - FALHA NO SISTEMA DE LANÇAMENTO E RECUPERAÇÃO
            </option>
            <option value="Aprisionamento de Mergulhador no Fundo">
              TOPO D -  APRISIONAMENTO DE MERGULHADOR NO FUNDO
            </option>
            <option value="Exposição Inadequada do Mergulhador na Interface de Sistemas Com Diferencial de Pressão">
              TOPO E - EXPOSIÇÃO INADEQUADA DO MERGULHADOR NA INTERFACE DE SISTEMAS COM
              DIFERENCIAL DE PRESSÃO{' '}
            </option>
            <option value="INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES DO SISTEMA DE MERGULHO">
             TOPO G - INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES DO
              SISTEMA DE MERGULHO{' '}
            </option>
            <option value="Falha do Equipamento Individual do Mergulhador">
             TOPO H -  FALHA DO EQUIPAMENTO INDIVIDUAL DO MERGULHADOR
            </option>
            <option value="Contato Acidental do Mergulhador com os Propulsores">
             TOPO J -  CONTATO ACIDENTAL DO MERGULHADOR COM OS PROPULSORES{' '}
            </option>
            <option value="Exposição dos Mergulhadores aos Contaminantes">
             TOPO K - EXPOSIÇÃO DOS MERGULHADORES AOS CONTAMINANTES{' '}
            </option>
            <option value="EXPOSIÇÃO A DESCARGAS ELÉTRICAS, IMPACTOS MECÂNICOS E FLUIDOS PRESSURIZADOS NO CORPO DO MERGULHADOR (SUBMERSOS/EMERSOS)">
             TOPO L -  EXPOSIÇÃO A DESCARGAS ELÉTRICAS, IMPACTOS MECÂNICOS E FLUIDOS
              PRESSURIZADOS NO CORPO DO MERGULHADOR (SUBMERSOS/EMERSOS){' '}
            </option>
            <option value="Exposição do Mergulhador A Vida Marinha Nociva">
             TOPO M -  EXPOSIÇÃO DO MERGULHADOR A VIDA MARINHA NOCIVA{' '}
            </option>
            <option value="Emergências não hiberbáricas (alteração clínica do mergulhador)">
             TOPO N -  EMERGÊNCIAS NÃO HIBERBÁRICAS (ALTERAÇÃO CLÍNICA DO MERGULHADOR){' '}
            </option>
          </select>
          <select
            id="Select-Embarcacao"
            class="form-select"
            aria-label="Default select example"
            onChange={searchSelectedForm}
          >
            <option selected>Selecione a Embarcação</option>
            <option value="Oceânica Sub IV">SDSV Oceânica Sub IV</option>
            <option value="Oceânica Sub V">SDSV Oceânica Sub V</option>
            <option value="Parcel de Manuel Luis">Parcel de Manuel Luis</option>
            <option value="Thor II">Thor II</option>
            <option value="Skandi Carla (DOF)">Skandi Carla (DOF)</option>
            <option value="Oceânica sub18">Oceânica sub18</option>

          </select>

          {SelectedEmpresa ? (
            <select
              id="Select-Embarcacao"
              class="form-select"
              aria-label="Default select example"
              onChange={DiagramResult}
            >
              <option selected>Selecione o Formulário</option>
              {ListDateAndForm.map((el, index) => {
                return (
                  <option value={index}>
                    Formulário {index} | Nome: {el.name} | Data: {el.Data}{' '}
                  </option>
                );
              })}
            </select>
          ) : (
            <select
              id="Select-Embarcacao"
              class="form-select"
              aria-label="Default select example"
            >
              <option selected>Selecione o Formulário</option>
            </select>
          )}
        </div>
      ) : (
        ''
      )}

      <h1 class="text-center">{titleForm}</h1>

      <form id="form-diagram" onSubmit={handleSubmit}>
        <div class="d-flex">
          <select
            class="form-select"
            aria-label="Default select example"
            onChange={GetFormModel}
            style={{ display: displayFormOptions ? 'block' : 'none' }}
          >
            <option selected>Selecione o Evento</option>
            <option value="Acidentes Hiperbaricos">
             TOPO A - ACIDENTES HIPERBARICOS
            </option>
            <option value="Falta de Suprimento Respiratório">
             TOPO B - FALTA DE SUPRIMENTO RESPIRATÓRIO
            </option>
            <option value="Falha no Sistema de Lançamento e Recuperação">
              TOPO C - FALHA NO SISTEMA DE LANÇAMENTO E RECUPERAÇÃO
            </option>
            <option value="Aprisionamento de Mergulhador no Fundo">
              TOPO D -  APRISIONAMENTO DE MERGULHADOR NO FUNDO
            </option>
            <option value="Exposição Inadequada do Mergulhador na Interface de Sistemas Com Diferencial de Pressão">
              TOPO E - EXPOSIÇÃO INADEQUADA DO MERGULHADOR NA INTERFACE DE SISTEMAS COM
              DIFERENCIAL DE PRESSÃO{' '}
            </option>
            <option value="INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES DO SISTEMA DE MERGULHO">
             TOPO G - INCÊNDIO E/OU EXPLOSÃO EM UMA CÂMARA OU NAS PROXIMIDADES DO
              SISTEMA DE MERGULHO{' '}
            </option>
            <option value="Falha do Equipamento Individual do Mergulhador">
             TOPO H -  FALHA DO EQUIPAMENTO INDIVIDUAL DO MERGULHADOR
            </option>
            <option value="Contato Acidental do Mergulhador com os Propulsores">
             TOPO J -  CONTATO ACIDENTAL DO MERGULHADOR COM OS PROPULSORES{' '}
            </option>
            <option value="Exposição dos Mergulhadores aos Contaminantes">
             TOPO K - EXPOSIÇÃO DOS MERGULHADORES AOS CONTAMINANTES{' '}
            </option>
            <option value="EXPOSIÇÃO A DESCARGAS ELÉTRICAS, IMPACTOS MECÂNICOS E FLUIDOS PRESSURIZADOS NO CORPO DO MERGULHADOR (SUBMERSOS/EMERSOS)">
             TOPO L -  EXPOSIÇÃO A DESCARGAS ELÉTRICAS, IMPACTOS MECÂNICOS E FLUIDOS
              PRESSURIZADOS NO CORPO DO MERGULHADOR (SUBMERSOS/EMERSOS){' '}
            </option>
            <option value="Exposição do Mergulhador A Vida Marinha Nociva">
             TOPO M -  EXPOSIÇÃO DO MERGULHADOR A VIDA MARINHA NOCIVA{' '}
            </option>
            <option value="Emergências não hiberbáricas (alteração clínica do mergulhador)">
             TOPO N -  EMERGÊNCIAS NÃO HIBERBÁRICAS (ALTERAÇÃO CLÍNICA DO MERGULHADOR){' '}
            </option>
          </select>
          <select
            class="form-select"
            aria-label="Default select example"
            style={{ display: displayFormOptions ? 'block' : 'none' }}
          >
            <option selected>Selecione a embarcação</option>
            <option value="Oceânica Sub IV">Oceânica Sub IV</option>
            <option value="Oceânica Sub V">SDSV Oceânica Sub V</option>
            <option value="Parcel de Manuel Luis">Parcel de Manuel Luis</option>
          </select>

          <div
            class="form-group"
            style={{ display: displayFormOptions ? 'block' : 'none' }}
          >
            <div class="d-flex">
              <label id="LabelUser" for="exampleInputEmail1">
                Nome:
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                placeholder="Nome Completo"
                name="name"
              />
            </div>
          </div>
          <div
            class="form-group"
            style={{ display: displayFormOptions ? 'block' : 'none' }}
          >
            <div class="d-flex">
              <label id="LabelUser">Email:</label>
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                name="email"
              />
            </div>
          </div>
          <div
            class="form-group"
            style={{ display: displayFormOptions ? 'block' : 'none' }}
          >
            <div class="d-flex">
              <label id="LabelUser">Telefone: </label>
              <input
                type="tel"
                class="form-control"
                placeholder="Telefone"
                name="telefone"
              />
            </div>
          </div>

          <div
            class="form-group"
            style={{ display: displayFormOptions ? 'block' : 'none' }}
          >
            <div class="d-flex">
              <label id="LabelUser">Data: </label>
              <input
                type="text"
                class="form-control"
                value={StringDate}
                disabled
                name="date"
              />
            </div>
          </div>
        </div>
        {selectedUpdate
          ? Object.keys(TitlesAndQuestions).map(FirstKey => {
              return (
                <div>
                  <h2>
                    {localStorage.getItem('name') === 'secret@key' ||
                    localStorage.getItem('name') === 'auditortrue' ||
                    localStorage.getItem('name') === 'petrobrastrue'
                      ? `${FirstKey}`
                      : FirstKey === listAMCO[0]
                      ? 'BARREIRAS PREVENTIVAS'
                      : FirstKey === listAMCO[1]
                      ? 'BARREIRAS MITIGADORAS'
                      : ''}
                  </h2>

                  {Object.keys(TitlesAndQuestions[FirstKey]).map(SecondKey => {
                    return (
                      <div
                        class="form-group"
                        id={
                          SecondId +
                          SecondKey.split(' ')[0] +
                          SecondKey.split(' ')[1]
                        }
                      >
                        <div>
                          <p for="exampleInputEmail1">
                            {localStorage.getItem('name') === 'secret@key'
                              ? `${SecondKey.split(' ')[0]} ${
                                  SecondKey.split(' ')[1]
                                }` +
                                ' ' +
                                TitlesAndQuestions[FirstKey][SecondKey][
                                  'Question'
                                ]
                              : ` ${SecondKey.split(' ')[2]}` +
                                ' ' +
                                TitlesAndQuestions[FirstKey][SecondKey][
                                  'Question'
                                ]}
                          </p>
                          <div>
                            {displaySelectOptions && (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <select
                                  class="form-select"
                                  aria-label="Default select example"
                                  style={{
                                    display: 'block',
                                  }}
                                  value={findAnswer(SecondKey)}
                                  onChange={e => {
                                    handleUpdateSelectedAnswer(e, SecondKey);
                                  }}
                                >
                                  <option selected>
                                    Selecione sua resposta
                                  </option>
                                  <option value="Yes">Sim</option>
                                  <option value="No">Não</option>
                                  <option value="">Não Aplicável</option>
                                </select>

                                {/* <input
                                  type="text"
                                  value={findComment(SecondKey)}
                                  onChange={e => {
                                    handleComments(e, SecondKey);
                                  }}
                                  style={{ width: '30%', height: 25 }}
                                  class="form-control"
                                  placeholder="Comentário"
                                /> */}
                              </div>
                            )}

                            {displayCheckOptions && (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      name={
                                        SecondKey +
                                        'Space@' +
                                        TitlesAndQuestions[FirstKey][SecondKey][
                                          'Question'
                                        ]
                                      }
                                      id={
                                        TitlesAndQuestions[FirstKey][SecondKey][
                                          'Question'
                                        ] + '|1'
                                      }
                                      key={
                                        TitlesAndQuestions[FirstKey][SecondKey][
                                          'Question'
                                        ] + '|1'
                                      }
                                      value="Yes"
                                      onChange={HandleChange}
                                    />
                                    <label
                                      class="form-check-label"
                                      for={
                                        TitlesAndQuestions[FirstKey][SecondKey][
                                          'Question'
                                        ] + ' 1'
                                      }
                                    >
                                      Sim
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      name={
                                        SecondKey +
                                        'Space@' +
                                        TitlesAndQuestions[FirstKey][SecondKey][
                                          'Question'
                                        ]
                                      }
                                      id={
                                        TitlesAndQuestions[FirstKey][SecondKey][
                                          'Question'
                                        ] + '|2'
                                      }
                                      key={
                                        TitlesAndQuestions[FirstKey][SecondKey][
                                          'Question'
                                        ] + '|2'
                                      }
                                      value="No"
                                      onChange={HandleChange}
                                    />
                                    <label
                                      class="form-check-label"
                                      for={
                                        TitlesAndQuestions[FirstKey][SecondKey][
                                          'Question'
                                        ] + ' 2'
                                      }
                                    >
                                      Não
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      name={
                                        SecondKey +
                                        'Space@' +
                                        TitlesAndQuestions[FirstKey][SecondKey][
                                          'Question'
                                        ]
                                      }
                                      id={
                                        TitlesAndQuestions[FirstKey][SecondKey][
                                          'Question'
                                        ] + '|3'
                                      }
                                      key={
                                        TitlesAndQuestions[FirstKey][SecondKey][
                                          'Question'
                                        ] + '|3'
                                      }
                                      value=""
                                      onChange={HandleChange}
                                    />
                                    <label
                                      class="form-check-label"
                                      for={
                                        TitlesAndQuestions[FirstKey][SecondKey][
                                          'Question'
                                        ] + ' 3'
                                      }
                                    >
                                      Não Aplicável
                                    </label>
                                  </div>
                                </div>
                                {/* <input
                                  type="text"
                                  value={findComment(SecondKey)}
                                  onChange={e => {
                                    handleComments(e, SecondKey);
                                  }}
                                  style={{ width: '30%', height: 25 }}
                                  class="form-control"
                                  placeholder="Comentário"
                                /> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          : ''}
        <div class="d-flex justify-content-center">
          {submitButton ? (
            <button type="submit" class="btn btn-primary">
              Enviar
            </button>
          ) : (
            ''
          )}
        </div>
      </form>
      <div class="d-flex justify-content-center">
        {updateButton && localStorage.getItem('name') === 'auditortrue'  && (
          <button
            type="button"
            id="updateButton"
            class="btn btn-warning"
            onClick={handleUpdate}
          >
            Atualizar
          </button>
        )}
      </div>
    </div>
  );
}
