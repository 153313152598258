import api from '../../../../../services/api';
import React, { useState } from 'react';
import Xarrow from 'react-xarrows';
import { listKeysQuestionFaltaRespiratorio } from '../../../checklist/formsModel/formFaltaRespiratorioModelNew';

import '../../style.css';

export default function Diagrama1() {
  const [listKeysQuestion, setListKeys] = useState(listKeysQuestionFaltaRespiratorio);


  let [NameUser,setNameUser] = useState('');
  let [EmailUser,setEmailUser]=useState('');
  let [DateUser,setDateUser]=useState('');


  const ResultClick = event => {


    api
      .get('formchecklist', { params: { empresa: event.target.value } })
      .then(res => {
        let data = res.data;
        setListKeys(data[data.length - 1].AnswerDiagram);
        setNameUser(data[data.length - 1].DataUser.name);
        setEmailUser(data[data.length - 1].DataUser.email);
        setDateUser(data[data.length - 1].DataUser.data);
      })
      .catch(error => {
        window.alert('Dados não encontrados');
      });
  };

  return (
    <div>

<select id="Select-Embarcacao" onChange={ResultClick} class="form-select" aria-label="Default select example">
  <option selected>Selecione a Embarcação</option>
  <option value="Oceânica Sub IV">SDSV Oceânica Sub IV</option>
  <option value="Oceânica Sub V">SDSV Oceânica Sub V</option>
  <option value="Seacor Grant">SDSV Seacor Grant</option>
  <option value="Thor II">Thor II</option>
  <option value="Skandi Carla (DOF)">Skandi Carla (DOF)</option>
  <option value="Oceânica sub18">Oceânica sub18</option>
</select>

      <div id='name-user'>
      <h3>Nome:{NameUser}</h3>
      <h3>Email:{EmailUser}</h3>
      <h3>Data:{DateUser}</h3>
      </div>

      <div class="d-flex Block-Diagram">
        <div class="AM">
          <div class="d-flex" id="AM1">
            <p id="FirstBox1">AM1-Erro Humano</p>

            {listKeysQuestion[0]['AM 1-Erro Humano'].map(key => {
              return (
                <div>
                  <div
                    class="answer-box1"
                    id={Object.keys(key)[0]}
                    style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                  >
                    {'BP.' + Object.keys(key)[0]}
                  </div>
                </div>
              );
            })}
            <Xarrow
              start="1-6"
              end="Event"
              startAnchor={'right'}
              endAnchor={'left'}
              showTail={true}
              showHead={false}
              color="#343194"
            />
          </div>

          <div class="d-flex" id="AM2">
            <p id="FirstBox2">
              AM2-Falha de equipamento de mergulho (compressor, quadro de
              cilindros, mangueira, capacete, bail out)
            </p>

            {listKeysQuestion[0][
              'AM 2-Falha de equipamento de mergulho (compressor, quadro de cilindros, mangueira, capacete, bail out)'
            ].map(key => {
              return (
                <div>
                  <div
                    class="answer-box1"
                    id={Object.keys(key)[0]}
                    style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                  >
                    {'BP.' + Object.keys(key)[0]}
                  </div>
                </div>
              );
            })}

            <Xarrow
              start="2-7"
              end="Event"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />
          </div>

          <div class="d-flex" id="AM3">
            <p id="FirstBox3">
              AM3-Esmagamento, torção, tensão e ruptura do umbilical
            </p>
            {listKeysQuestion[0][
              'AM 3-Esmagamento, torção, tensão e ruptura do umbilical'
            ].map(key => {
              return (
                <div>
                  <div
                    class="answer-box1"
                    id={Object.keys(key)[0]}
                    style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                  >
                    {'BP.' + Object.keys(key)[0]}
                  </div>
                </div>
              );
            })}
            <Xarrow
              start="3-9"
              end="Event"
              startAnchor={'right'}
              endAnchor={'left'}
              showHead={false}
              showTail={true}
              color="#343194"
            />
          </div>

          <div class="d-flex" id="AM4">
            <p id="FirstBox4">
              AM 4-Danos mecânicos (choque) à estrutura do capacete (sideblock,
              visor, estrutura, etc)
            </p>
            {listKeysQuestion[0][
              'AM 4-Danos mecânicos (choque) à estrutura do capacete (sideblock, visor, estrutura, etc)'
            ].map(key => {
              return (
                <div>
                  <div
                    class="answer-box1"
                    id={Object.keys(key)[0]}
                    style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                  >
                    {'BP.' + Object.keys(key)[0]}
                  </div>
                </div>
              );
            })}
          </div>

          <Xarrow
            start="4-5"
            end="Event"
            startAnchor={'right'}
            endAnchor={'left'}
            showHead={false}
            showTail={true}
            color="#343194"
          />
        </div>

        <div id="Event-Block">
          <div id="Hazard">
            <p id="Title-Hazard">Risco</p>
          </div>
          <Xarrow start="Hazard" end="Event" color="black" />
          <div id="Event">Falta de Suprimento Respiratório</div>
        </div>

        <div class="CO">
          <div class="d-flex">
            {listKeysQuestion[0]['CO 1-Doença descompressiva'].map(key => {
              return (
                <div>
                  <div
                    class="answer-box2"
                    id={Object.keys(key)[0]}
                    style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                  >
                    {'BM.' + Object.keys(key)[0]}
                  </div>
                </div>
              );
            })}
            <p id="SecondBox1">CO 1 Doença Descompressiva</p>

            <Xarrow
              start="5-1"
              end="Event"
              color="#b8291a"
              showTail={true}
              showHead={false}
              endAnchor={'right'}
            />
          </div>
          <div class="d-flex">
            {listKeysQuestion[0]['CO 2-Afogamento'].map(key => {
              return (
                <div>
                  <div
                    class="answer-box2"
                    id={Object.keys(key)[0]}
                    style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                  >
                    {'BM.' + Object.keys(key)[0]}
                  </div>
                </div>
              );
            })}
            <p id="SecondBox2">CO2 Afogamento</p>

            <Xarrow
              start="6-1"
              end="Event"
              color="#b8291a"
              showTail={true}
              showHead={false}
              endAnchor={'right'}
            />
          </div>
          <div class="d-flex">
            {listKeysQuestion[0]['CO 3 -Asfixia'].map(key => {
              return (
                <div>
                  <div
                    class="answer-box2"
                    id={Object.keys(key)[0]}
                    style={{ background: key[Object.keys(key)[0]]['Answer'] }}
                  >
                    {'BM.' + Object.keys(key)[0]}
                  </div>
                </div>
              );
            })}
            <p id="SecondBox3">CO3 Asfixia</p>

            <Xarrow
              start="7-1"
              end="Event"
              color="#b8291a"
              showTail={true}
              showHead={false}
              endAnchor={'right'}
            />
          </div>
        </div>
      </div>
      <a href="https://ibb.co/2W9LfzW">
        <img
          id="legend"
          src="https://i.ibb.co/ckBp7Wk/Legenda-Bowtie-1.png"
          alt="Legenda-Bowtie-1"
          border="0"
        />
      </a>
      <div id="Table-Legend-Block">
        <div class="d-flex">
          <a href="https://ibb.co/KbhpSxq">
            <img
              id="table-legend-AM"
              src="https://i.ibb.co/bQmMD5B/legenda-AM1-new.png"
              alt="legenda-AM1-new"
              border="0"
            />
          </a>
          <a href="https://ibb.co/pzbBSqz">
            <img
              id="table-legend-AM"
              src="https://i.ibb.co/HzBfjZz/legenda-AM2-new.png"
              alt="legenda-AM2-new"
              border="0"
            />
          </a>
          <a href="https://ibb.co/QjrdCnP">
            <img
              id="table-legend-AM"
              src="https://i.ibb.co/XkS83p7/legenda-AM3-new.png"
              alt="legenda-AM3-new"
              border="0"
            />
          </a>
          <a href="https://ibb.co/wW5yyfx">
            <img
              id="table-legend-AM"
              src="https://i.ibb.co/6PjYYkf/legenda-AM4-new.png"
              alt="legenda-AM4-new"
              border="0"
            />
          </a>
        </div>

        <div class="d-flex">
          <a id="CO-link" href="https://ibb.co/SJLLx7n">
            <img
              id="table-legend"
              src="https://i.ibb.co/x344s8j/Legenda-CO1.png"
              alt="Legenda-CO1"
              border="0"
            />
          </a>
          <a id="CO-link" href="https://ibb.co/hLV8NXZ">
            <img
              id="table-legend"
              src="https://i.ibb.co/MZ7NH8G/LEGENDA-CO2.png"
              alt="LEGENDA-CO2"
              border="0"
            />
          </a>
          <a id="CO-link" href="https://ibb.co/09pLv2K">
            <img
              id="table-legend"
              src="https://i.ibb.co/HPLy34C/l-EGENDA-CO3.png"
              alt="l-EGENDA-CO3"
              border="0"
            />
          </a>
        </div>
      </div>
    </div>
  );
}


